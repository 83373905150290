@import "~scss/imports";

.element {
  display: grid;
  grid-template-rows: 1fr 1fr;
  text-decoration: none;

  &:hover > div {
    background-color: var(--green);
  }
}

.content {
  background-color: var(--black);
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--pad);
  position: relative;

  &:before {
    background-color: var(--white);
    content: "";
    height: var(--barheight);
    left: 0;
    position: absolute;
    top: 0;
    width: 50%;
  }
}

.heading {
  @extend %h5;
  font-size: var(--h3);
  line-height: var(--h2);
  margin: 0;

  @media (min-width: 1024px) {
    font-size: var(--h5);
    line-height: var(--line-h5);
  }
}

.copy {
  @extend %sub2;
  margin: 1rem 0;
}

.image {
  display: block;
  width: 100%;

  img {
    height: 100%;
    object-fit: cover;
  }
}

.meta {
  @extend %sub2;
  display: flex;
  justify-content: space-between;
}

.list {
  display: grid;
  grid-gap: 30px;
  margin-top: var(--pad);

  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}