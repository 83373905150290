@import "~scss/imports";

.element {
  background-color: var(--black);
  color: var(--white);
  padding: var(--pad);

  @media (min-width: 1024px) {
    padding: var(--pad) var(--marginX);
    padding-bottom: calc(var(--marginX) / 2);
  }

  svg {
    height: auto;
    max-width: 150px;
    width: 100%;

    rect {
      stroke: var(--white);
      fill: var(--black);
    }

    path {
      fill: var(--white);
    }
  }

  a, p {
    color: var(--white);
  }
}

.form {
  p {
    margin-top: 0;
  }

  form {
    width: 100%;
  }

  form > div {
    display: flex;
  }

  input[type="email"] {
    @extend %graphik-regular;
    background-color: var(--black);
    border-right: 0;
    border: 2px solid var(--white);
    color: var(--white);
    padding: 5px 10px;

    &::placeholder {
      color: var(--white);
    }

    &:focus {
      background-color: rgba(255,255,255,0.2);
      outline: none;
    }
  }
  
  input[type="submit"],
  button {
    @extend %graphik-bold;
    background-color: var(--black);
    border: 2px solid var(--white);
    color: var(--white);
    padding: 5px 10px;
    text-transform: uppercase;
    transition: all 0.3s;

    &:hover {
      background-color: var(--white);
      color: var(--black);
      cursor: pointer;
    }
  }
}

.blocks {
  display: grid;
  grid-gap: 1rem;

  @extend %maxwidth;
  
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr ;
    grid-gap: var(--gridgap);

  }
}

.content,
.content p {
  margin: 5px 0;
}

.copyright {
  @extend %graphik-regular;
  font-size: 10px;
  text-align: center;
  width: 100%;

  @extend %maxwidth;
  
  
  p {
    font-style: italic;
  }
}