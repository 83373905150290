@import "~scss/imports";

.element {
  padding: 0 var(--marginX);
  position: relative;
  margin: var(--pad) auto;

  @extend %maxwidth;
}

// .content {
//   height: 0;
//   overflow: hidden;
// }