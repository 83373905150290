@import "~scss/imports";

.element {
  align-items: center;
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  padding: 0 var(--marginX);
  position: sticky;
  top: 0;
  z-index: 1;

  @extend %maxwidth;
}

.list {
  height: 100%;
  justify-content: space-between;
  left: 0;
  list-style: none;
  margin: 0;
  overflow: auto;
  padding: 0;
  position: fixed;
  right: 0;
  top: calc(var(--navheight) + 20px);
  transition: transform 0.3s;

  transform: translateY(-110%);
  width: 100%;
  z-index: 9;

  &:global(.is-open) {
    transform: translateY(0);
  }

  @media (min-width: 1024px) {
    display: flex;
    height: auto;
    position: relative;
    top: calc(var(--navheight)*.75);
    width: 60%;
  }
}

.home {
  @extend %link;
}

.logo {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 8px;
  padding-left: 0;
  z-index: 11;
  
  svg {
    height: auto;
    max-width: 112px;
    width: 100%;
  }
  
  @media (min-width: 1024px) {
    padding: 20px;
    padding-left: 0;
    
    svg {
      max-width: 200px;
      min-width: 180px;
    }
  }
}

.link {
  @extend %nav;
  display: block;
  padding: calc(var(--pad)*2) var(--pad);
  
  @media (min-width: 1024px) {
    padding: var(--pad) 0;
  }

  a:after {
    opacity: 0;
  }
  
  a:hover:after {
    opacity: 1;
  }
}

.toggle {
  appearance: none;
  background-color: transparent;
  border: none;
  border-bottom: 4px solid var(--black);
  border-top: 4px solid var(--black);
  height: 24px;
  outline: none;
  width: 32px;
  z-index: 10;

  position: absolute;
  right: 60px;
  top: 23px;


  @media (min-width: 1024px) {
    position: relative;
    top: unset;
    right: unset;
  }

  &:after,
  &:before {
    background-color: var(--black);
    bottom: 0;
    content: "";
    height: 32px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(90deg);
    transition: transform 0.3s;
    width: 4px;
  }

  &:global(.active) {
    border: none;

    &:before {
      transform: rotate(45deg);
      width: 4px;
    }
    &:after {
      transform: rotate(-45deg);
      width: 4px;
    }
  }
}

.button {
  appearance: none;
  background-color: transparent;
  border: none;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  margin: 0;
  outline: none;
  z-index: 10;
  text-transform: uppercase;

  .link {
    padding: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--black);
    color: var(--white);
  }
}