@import "~scss/imports";

.element {
  @extend %maxwidth;
  margin: var(--pad) auto;

  padding: 0 var(--marginX);
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (min-width: 1024px) {
    display: block;
  }
}

.block {
  display: grid;
  grid-gap: var(--gridgap);
  top: -2rem;
  position: relative;

  @media (min-width: 1024px) {
    grid-template-columns: 2fr 1fr;
    justify-content: center;
    align-items: flex-start;
    top: 0;
  }
}

.image {
  display: block;
  max-height: 720px;
  overflow: hidden;
}

.content {
  padding: var(--pad);
  position: relative;
  left: 1rem;
  width: 80%;
  
  @media (min-width: 1024px) {
    top: -50px;
    left: 60px;
    width: 80%;
  }

  // @media (min-width: 1440px) {
  //   width: 80%;
  // }
}

.heading {
  @extend %h4;
  margin-top: 0;
  padding-top: 32px;
  position: relative;

  &:before {
    content: "";
    background-color: var(--black);
    left: 0;
    position: absolute;
    height: var(--barheight);
    width: 50px;
    top: 0;
  }
}

.copy {
  @extend %sub1;
}

.link {
  @extend %cta;
  justify-self: flex-end;
  margin-right: 10%;
  order: 3;

  @media (min-width: 1024px) {
    justify-self: flex-start;
    margin-right: 0;
    padding-top: var(--pad);
  }
}