@import "type";
@import "colors";

%list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

%maxwidth {
  max-width: var(--max-width);
  margin: 0 auto;
}