@import "~scss/imports";

.element {
  // position: relative;
  padding: var(--pad) var(--marginX);

  @extend %maxwidth;
  margin: var(--pad) auto;
}

.grid {
  @extend %list-reset;
  display: grid;
  grid-gap: var(--gridgap);
  padding: 0 0 var(--gridgap);
  position: relative;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
}

@each $color in $colors {
  &:global(.block-#{nth($color,1)}) {
    h4 {
      background-color: rgba(nth($color,2), 0.5);

      &:global(.open),
      &:hover {
        background-color: nth($color,2);
      }
    }

    > div {
      border: 4px solid nth($color,2);
    }

    h3 {
      background-color: nth($color,2);
    }
  }
}

.blockname {
  @extend %h6;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  margin: 0;
  min-height: 50px;
  padding: 0 var(--pad);
  // padding: var(--pad);

  @media (min-width: 1024px) {
    min-height: 80px;
  }
  
  &:hover {
    cursor: pointer;
  }
}

.content {
  margin-top: var(--gridgap);
  padding: var(--pad) var(--marginX);

  > section {
    padding: 0;
  }
}