@import "~scss/imports";

.element {
  @extend %graphik-regular;
  @extend %maxwidth;

  a {
    @extend %link-inline;
  }

  h4 a {
    @extend %link;
  }  

  p {
    @extend %body;
    white-space: pre-wrap;
  }

  h2 { 
    @extend %h2;
  }

  h3 { 
    @extend %h3;
  }

  h4 { 
    @extend %h4;
  }

  blockquote {
    margin-left: 0;
    margin-right: 0;
    font-size: inherit;
    line-height: inherit;
  }
}