@import "~scss/imports";

.element {
  @extend %list-reset;
  margin: var(--pad) 0;
  padding: 0 var(--marginX);

  @extend %maxwidth;
  margin: var(--pad) auto;

  display: grid;
  grid-gap: var(--gridgap);

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.item {
  @media (min-width: 1024px) {
    min-width: 33.33%;
  }
}