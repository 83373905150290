%graphik-black {
  font-family: 'Graphik Web';
  font-weight:  900;
  font-style:   normal;
  font-stretch: normal;
}

%graphik-bold {
  font-family: 'Graphik Web';
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
}

%graphik-semibold {
  font-family: 'Graphik Web';
  font-weight:  600;
  font-style:   normal;
  font-stretch: normal;
}

%graphik-medium {
  font-family: 'Graphik Web';
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
}

%graphik-regular {
  font-family: 'Graphik Web';
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}

%h1 {
  @extend %graphik-black;
  font-size: var(--h1);
  line-height: var(--line-h1);
}

%h2 {
  @extend %graphik-bold;
  font-size: var(--h2);
  line-height: var(--line-h2);
}

%h3 {
  @extend %graphik-bold;
  font-size: var(--h3);
}

%h4 {
  @extend %graphik-semibold;
  font-size: var(--h4);
}

%h5 {
  @extend %graphik-regular;
  font-size: var(--h5);
  line-height: var(--line-h5);
}

%h6 {
  @extend %graphik-regular;
  font-size: var(--h6);
  line-height: var(--line-h6);
}

%body {
  @extend %graphik-regular;
  font-size: var(--body);
}

%sub1 {
  @extend %graphik-regular;
  font-size: var(--sub1);
  line-height: var(--line-sub1);
}

%sub2 {
  @extend %graphik-regular;
  font-size: var(--sub2);
  // line-height: var(--line-sub1);
}

%cta {
  @extend %graphik-bold;
  font-size: var(--h5);
}

%quote {
  @extend %graphik-regular;
  font-size: var(--h5);
  line-height: var(--line-h4);
}

%breadcrumb {
  @extend %graphik-semibold;
  font-size: var(--label);
  text-transform: uppercase;
}

%nav {
  @extend %graphik-semibold;
  font-size: var(--nav-link);
}

%link {
  color: var(--black);
  padding-bottom: 8px;
  position: relative;
  text-decoration: none;

  &:after {
    background-color: var(--black);
    bottom: 0;
    content: '';
    height: 4px;
    left: 0;
    position: absolute;
    right: 0;
  }

  &.has-arrow:after {
    right: 12px;
  }

  @media (min-width: 1024px) {
    &.has-arrow:after {
      right: 24px;
    }
  }

  &.blue {
    &:hover:after {
      background-color: var(--blue);
    }
  }
  &.red {
    &:hover:after {
      background-color: var(--red);
    }
  }
  &.yellow {
    &:hover:after {
      background-color: var(--yellow);
    }
  }
  &.green {
    &:hover:after {
      background-color: var(--green);
    }
  }
}

%link-inline {
  border-bottom: 1px solid var(--black);
  color: var(--black);
  padding-bottom: 0;
  text-decoration: none;
}