@import "~scss/imports";

.heading {
  @extend %h4;
  margin: 1rem 0;
}

.subheading {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  @extend %body;

  span {
    margin-left: 5px;
  }
}

.element {
  align-items: center;
  display: flex;
  justify-content: space-between;

  &:hover {
    border-bottom-color: var(--black) !important;
    cursor: pointer;
  }

  span {
    @extend %h4;
  }
}

.children {
  margin-top: var(--gridgap);
}