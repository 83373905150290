$white: #fff;
$black: #000;
$blue: #1FA1DB;
$yellow: #FCC930;
$green: #21B254;
$red: #ED2B4F;

$colors: (
  'white': $white,
  'black': $black,
  'blue': $blue,
  'yellow': $yellow,
  'green': $green,
  'red': $red,
);
