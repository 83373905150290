:root {
  // colors
  --white: #fff;
  --black: #000;
  --blue: #1FA1DB;
  --yellow: #FCC930;
  --green: #21B254;
  --red: #ED2B4F;

  // breakpoints
  --mobile: 320px;
  --tablet: 800px;
  --desktop: 1440px;

  // font sizes
  --h1: 36px;
  --line-h1: 40px;
  --h2: 24px;
  --h3: 18px;
  --line-h3: 20px;
  --h4: 18px;
  --line-h4: 22px;
  --h5: 14px;
  --line-h5: 18px;
  --h6: 12px;
  --line-h6: 16px;
  --sub1: 14px;
  --line-sub1: 16px;
  --sub2: 14px;
  --nav-link: 22px;
  --body: 16px;
  --label: 12px;
  

  // spacing
  --marginX: 8px;
  --pad: 1rem;
  --gridgap: 8px;
  
  // heights 
  --navheight: 64px;
  --barheight: 4px;

  --max-width: 1400px;

  --max-width-sm: 900px;

  @media (min-width: 1024px) {
    --h1: 64px;
    --line-h1: 70px;
    --h2: 56px;
    --h3: 48px;
    --line-h3: 52px;
    --h4: 32px;
    --line-h4: 40px;
    --h5: 24px;
    --line-h5: 32px;
    --h6: 20px;
    --line-h6: 26px;
    --sub1: 18px;
    --line-sub1: 24px;

    --marginX: 30px;
    --pad: 2rem;
    --gridgap: 24px;
    
    --navheight: 136px;
    --barheight: 8px;
  }

  @media (min-width: 1440px) {
    --marginX: 100px;
  }
}
