@import "~scss/imports";

.element {
  margin: var(--pad) 0;
  padding: 0 var(--marginX);

  @extend %maxwidth;
  margin: var(--pad) auto;
}

.heading {
  @extend %h2;
  margin-bottom: 0;
}

.subheading {
  @extend %h5;
  max-width: var(--max-width-sm);
}