@import "~scss/imports";

.element {
  display: grid;
  padding: 0 var(--marginX);
  grid-template-columns: 1fr;
  
  @extend %maxwidth;
  margin: var(--pad) auto;
  
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
}

.content {
  order: 1;
  
  @media (min-width: 1024px) {
    order: 0;
    padding-right: var(--pad);
    
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.heading {
  @extend %h1;
  margin: 1rem 0;
  white-space: pre-wrap;
  width: 75%;

  @media (min-width: 800px) {
    width: 60%;
  }
  
  @media (min-width: 1024px) {
    width: auto;
  }
}

.subheading {
  @extend %h6;

  // TODO this is annoying
  p {
    font-size: var(--h6) !important;
  }
}