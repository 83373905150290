@import "~scss/imports";

.footer {
  margin-top: 50px;
  @media (min-width: 1024px) {
    margin-top: 100px;
  }
}

.element {
  list-style:none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
}

.item {
  @extend %h3;
  width: 100%;
  
  @media (min-width: 1024px) {
    min-width: 33.333%;
    width: 50%;
  }
}

.link {
  align-items: center;
  color: var(--black);
  display: flex;
  height: calc(100% - var(--pad));
  justify-content: space-between;
  padding: calc(var(--pad)/2) var(--pad);
  text-decoration: none;

  svg {
    max-width: 24px;
    height: auto;
    width: 100%;
  }

  &:hover {
    background-color: var(--black);
    color: var(--white);

    svg * {
      fill: var(--white);
    }
  }

  @media (min-width: 1024px) {
    svg {
      max-width: 48px;
    }
  }
}