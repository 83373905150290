@import "~scss/imports";

.element {
  background-color: var(--red);
  padding: 8px var(--marginX);
  position: sticky;
  top: 0;
  z-index: 2;

  > div {
    @extend %maxwidth;
  }
  
  p {
    @extend %nav;
    font-size: 12px;
    margin: 0;
  }

  a {
    text-decoration: none;
    border-bottom-width: 2px;

    &:hover {
      border-bottom-color: var(--red);
    }
  }

  @media (min-width: 1024px) {
    text-align: center;

    p {
      font-size: var(--nav-link);
    }
  }
}