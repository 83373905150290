@import "~scss/imports";

.element {
  align-items: center;
  border-top: 4px solid var(--black);
  color: var(--black);
  display: flex;
  justify-content: space-between;
  padding: var(--pad);
  text-decoration: none;
  
  svg {
    max-width: 30px;
  }

  @media (min-width: 1024px) {
    align-items: flex-start;
    border-top: 8px solid var(--black);
    
    svg {
      max-width: 44px;
    }
  }
  @media (min-width: 1200px) {
    align-items: flex-end;
  }

  &:hover {
    background-color: var(--black) !important;
    color: var(--white);

    svg * {
      fill: var(--white);
    }
  }
}

.text {
  @extend %h2;
  display: inline-block;
  max-width: 50%;
}