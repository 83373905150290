@import "~scss/imports";

.element {
  margin: var(--pad) 0;
  padding: 0 var(--marginX);

  @extend %maxwidth;
  margin: var(--pad) auto;

  &:global(.nested) > h3 {
    @extend %breadcrumb;
    display: inline-block;
    padding: 10px;
    margin-bottom: 0;
  }

  &:global(.accordion) {
    max-width: var(--max-width-sm);
    margin: 0 auto;
  }
}

.heading {
  @extend %h2;
  margin-bottom: 0;
}

.subheading {
  @extend %h5;
}

.list {
  display: grid;
  grid-gap: 30px;
  margin-top: var(--pad);

  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}