@import "~scss/imports";

.element {
  text-align: center;
  min-height: 50vh;

  h1 {
    @extend %graphik-bold;
    font-size: 80px;
    margin: var(--pad);

    @media (min-width: 800px) {
      font-size: 200px;
    }
  }

  h2 {
    @extend %h2;
  }

  svg {
    width: 100%;
    height: auto;
  }
}

.copy {
  @extend %body;
  padding: var(--pad);

  p + p {
    margin: 0;
  }
}

.link {
  @extend %link-inline;
}