@import "~scss/imports";

.heading {
  @extend %h1;
  padding: var(--pad) var(--marginX);
  @extend %maxwidth;
}

.maxwidth {
  @extend %maxwidth;
}