@import "~scss/imports";

.element {
  background-color: var(--white);
  list-style: none;
  margin: 0;
  padding: var(--pad);
  position: absolute;
  right: var(--marginX);
  top: calc(100% - 20px);
}

.link {
  @extend %nav;
  border-bottom: 4px solid transparent;
  color: var(--black);
  display: inline-block;
  margin-bottom: 4px;
  text-decoration: none;

  &:hover {
    border-bottom: 4px solid var(--black);
  }
}