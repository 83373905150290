@import "~scss/imports";

.element {
  max-width: 800px;
  margin: 0 auto;
  padding: var(--pad) var(--marginX);
  position: relative;
}

.heading {
  @extend %breadcrumb;
}

.body {
  @extend %quote;

  p {
    @extend %quote;
  }
}

.attr {
  @extend %h6;
}

.accent {
  position: relative;
  width: 50%;
  height: var(--barheight);
}