@import "~scss/imports";

.element {
  padding: var(--pad) var(--marginX);
  @extend %maxwidth;

  @media (min-width: 1024px) {
    ul {
      columns: 2;
    }
  }

  h3 {
    padding-left: 0;
    padding-bottom: 0;
    margin: 0 0 var(--pad);
  }
  
  &:global(.accordion) {
    padding: 0;
    max-width: var(--max-width-sm);
    margin: 0;
    
    ul {
      columns: 1;
    }
  }
}

.heading {
  @extend %h4;
  position: relative;
}

.subheading {
  @extend %body;
  margin-bottom: 0;
}

.list {
  @extend %list-reset;
}

.item {
  position: relative;
  padding-left: 10px;

  &:before {
    background-color: var(--black);
    content: "";
    height: 8px;
    left: 0;
    position: absolute;
    top: 6px;
    width: 4px;
  }
}