@font-face {
  font-family: 'Graphik Web';
  src: url('../fonts/Graphik-Bold-Web.woff2') format('woff2'),
       url('../fonts/Graphik-Bold-Web.woff') format('woff');
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../fonts/Graphik-Semibold-Web.woff2') format('woff2'),
       url('../fonts/Graphik-Semibold-Web.woff') format('woff');
  font-weight:  600;
  font-style:   normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../fonts/Graphik-Medium-Web.woff2') format('woff2'),
       url('../fonts/Graphik-Medium-Web.woff') format('woff');
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../fonts/Graphik-Regular-Web.woff2') format('woff2'),
       url('../fonts/Graphik-Regular-Web.woff') format('woff');
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../fonts/Graphik-Black-Web.woff2') format('woff2'),
       url('../fonts/Graphik-Black-Web.woff') format('woff');
  font-weight:  900;
  font-style:   normal;
  font-stretch: normal;
}

// Black
// Bold
// Semibold
// Regular
// Medium