@import "~scss/imports";

.element {
  >figure {
    padding: var(--pad) var(--marginX);
    margin: 0;
  }
}

.header {
  max-width: var(--max-width-sm);
  padding: var(--pad) var(--marginX);
}

.headersplit {
  display: grid;
  padding: var(--pad) var(--marginX);
  grid-gap: var(--pad);

  @extend %maxwidth;

  picture {
    border-top: var(--barheight) solid var(--yellow);
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
}

.heading {
  @extend %h3;
  margin: 0;
}

.subheading {
  @extend %h6;
}

.link {
  @extend %cta;
}

.meta {
  margin-bottom: 2rem;

  p {
    @extend %sub2;
    background-color: var(--yellow);
    display: inline-block;
    margin-right: 8px;
    padding: 5px;
  }
}