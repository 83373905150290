@import "~scss/imports";

@each $color in $colors {
  // .color--#{nth($color,1)} {
  //   color: nth($color,2);
  // }
  // .backgroundColor--#{nth($color,1)} {
  //   background-color: nth($color,2);
  // }

  .accent-#{nth($color,1)} {
    h3.accordion {
      background-color: nth($color,2);
    }
  }
}

.accent-black {
  h3.accordion {
    color: var(--white);
  }
}

.open .side-by-side {
  padding: 0;
}
