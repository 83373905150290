@import "~scss/imports";

figure.side-by-side {
  margin: 0;

  @each $color in $colors {
    &.frame-#{nth($color,1)} {
      border-color: nth($color,2);
    }
  }
}

figure.side-by-side:first-of-type {
  border-top-width: var(--barheight);
  border-top-style: solid;
}

figure.side-by-side:last-of-type {
  border-top-width: var(--barheight);
  border-top-style: solid;

  @media (min-width: 1024px) {
    border-bottom-width: var(--barheight);
    border-bottom-style: solid;
    border-top: 0;
  }
}

.frame-none {
  border-color: transparent;
}
