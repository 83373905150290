@import "~scss/imports";

.element {
  @extend %h4;
  max-width: var(--max-width-sm);
  padding: var(--pad) var(--marginX);
  position: relative;

  @extend %maxwidth;

  &:before {
    background-color: inherit;
    content: "";
    height: var(--barheight);
    left: 0;
    position: absolute;
    top: 0;
    width: 50px;
  }

  &:global(.accordion) {
    @extend %breadcrumb;
    background-color: inherit;
    display: inline-block;
    padding: 10px 0;
    margin-bottom: 0;
  }

  &:global(.simple) {
    @extend %maxwidth;
    // margin: 0 var(--marginX);
    padding: var(--pad) 0;
  }
}