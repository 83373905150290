@import "~scss/imports";

.element {
  padding: 0 var(--marginX);
  
  @extend %maxwidth;
  
  &:global(.stack-pad + .stack-pad) {
    margin: calc(#{var(--pad)} + 1rem) auto;
  }
}

.heading {
  @extend %h2;
  max-width: var(--max-width-sm);
  margin-bottom: 0;
}

.headingsmall {
  @extend %h4;
  margin-bottom: 0;
  max-width: var(--max-width-sm);
  padding-top: calc(var(--barheight)*2);
  position: relative;

  &:before {
    content: "";
    height: var(--barheight);
    left: 0;
    position: absolute;
    top: 0;
    width: 50px;
  }
}


@each $color in $colors {
  &:global(.bar-#{nth($color,1)}):before {
    background-color: nth($color,2);
  }

  &:global(.side-by-side#{nth($color,1)}) {
    border-top: var(--barheight) solid nth($color,2);
    padding-left: 0;
  }
}

.subheading {
  @extend %h6;
  p {
    @extend %h6;
    max-width: var(--max-width-sm);
  }
}

.body {
  @extend %h6;
  
  p {
    @extend %h6;
    max-width: var(--max-width-sm);
  }
}