@import "~scss/imports";

.element {
  display: grid;
  grid-gap: var(--pad);
  padding: var(--pad) var(--marginX);

  @extend %maxwidth;
  
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  h2 {
    @extend %h3;
  }

  p {
    @extend %sub1;
  }
}