@import "~scss/imports";

.element {
  display: grid;
  grid-gap: var(--gridgap);
  padding: var(--pad) var(--marginX);
  
  @extend %maxwidth;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
}

.map,
.svg {
  height: auto;
  max-width: 100%;
}

.map path {
  &:hover {
    cursor: pointer;
  }
}

.content {
  padding: var(--pad);
  position: relative;

  p, a {
    @extend %sub1;
  }

  a {
    color: inherit;
  }

  // &:before {
  //   height: var(--barheight);
  //   position: absolute;
  //   top: calc(-1*#{var(--barheight)});
  //   left: 0;
  //   right: 0;
  //   background-color: var(--black);
  //   content: "";
  // }
  
  p + p {
    margin-bottom: 0;
  }
}