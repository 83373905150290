@import "~scss/imports";

.element {
  // margin: 0 auto;
  padding: var(--pad) var(--marginX);
  @extend %maxwidth;

  p {
    margin-top: 0;
  }
  
  p + p {
    margin-top: 1rem;
  }

  &:global(.accordion) {
    margin: 0;
    padding: 10px 0;
  }

  &:global(.simple) {
    margin: 0 auto;
    padding-top: 0;
  }
}