@import "~scss/imports";

.element {
  @extend %link;

  svg {
    margin-left: 5px;
    margin-bottom: -5px;
    max-width: 12px;
    height: auto;
    width: 100%;
  }

  &:global(.has-arrow) {
    white-space: nowrap;
  }

  &:global(.has-arrow):after {
    right: 15px;
  }

  @media (min-width: 1024px) {
    svg {
      max-width: 20px;
    }

    &:global(.has-arrow):after {
      right: 20px;
    }

    &:global(.active):after {
      opacity: 1;
    }
  }

  &:global(.blue) {
    &:after {
      background-color: var(--blue);
    }
  }
  &:global(.red) {
    &:after {
      background-color: var(--red);
    }
  }
  &:global(.yellow) {
    &:after {
      background-color: var(--yellow);
    }
  }
  &:global(.green) {
    &:after {
      background-color: var(--green);
    }
  }

  &:global(.hover-blue) {
    &:hover {
      svg * {
        fill: var(--blue);
      }
      
      &:after {
        background-color: var(--blue);
      }
    }
  }
  &:global(.hover-red) {
    &:hover {
      svg * {
        fill: var(--red);
      }
      
      &:after {
        background-color: var(--red);
      }
    }
  }
  &:global(.hover-yellow) {
    &:hover {
      svg * {
        fill: var(--yellow);
      }
      
      &:after {
        background-color: var(--yellow);
      }
    }
  }
  &:global(.hover-green) {
    &:hover {
      svg * {
        fill: var(--green);
      }
      
      &:after {
        background-color: var(--green);
      }
    }
  }
}