@import "~scss/imports";

.element {
  display: grid;
  max-width: var(--max-width);

  + .element {
    margin-top: var(--pad);
  }
  
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
}

.content {
  border-top: var(--barheight) solid var(--black);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--pad);
}

.heading {
  @extend %h4;
  margin: 0;
}

.copy {
  @extend %sub1;
  margin: 0;
  padding: 1rem 0;
}

.image {
  position: relative;
  picture {
    display: block;
    height: 100%;
    width: 100%;
  }

  img {
    min-height: 100%;
    object-fit: cover;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

.links {
  display: block;
  padding-bottom: calc(var(--barheight)*2);

  @media (min-width: 1024px) {
    a svg {
      max-width: 16px;
    }
  }
  
  a:hover {
    svg * {
      fill: var(--white);
    }

    &:after {
      background-color: var(--white);
    }
  }

  a + a {
    margin-left: var(--pad);
  }
}