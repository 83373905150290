@import "fonts";
@import "variables";


#google_translate_element {
  height: 0 !important;
  overflow: hidden !important;
  visibility: hidden !important;
}

.goog-tooltip:hover {
  display: none !important;
}

.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

// newsletter embed overrides
#mc_embed_signup {
  input[type="email"],
  input.email {
    font-family: 'Graphik Web' !important;
    font-weight:  400;
    background-color: var(--black) !important;
    border-radius: 0 !important;
    border: 2px solid var(--white) !important;;
    border-right: 0 !important;
    color: var(--white) !important;;
    padding: 5px 10px;
    line-height: 1;

    &::placeholder {
      color: var(--white);
    }

    &:focus {
      background-color: rgba(255,255,255,0.2);
      outline: none;
    }
  }
  
  input[type="submit"],
  button {
    font-family: 'Graphik Web' !important;
    font-weight:  700;
    background-color: var(--black);
    border: 2px solid var(--white);
    border-radius: 0;
    line-height: 1;
    color: var(--white);
    padding: 5px 10px;
    text-transform: uppercase;
    transition: all 0.3s;

    &:hover {
      background-color: var(--white);
      color: var(--black);
      cursor: pointer;
    }
  }
}


// SSR missing class bug fallback...
.nav-element {
  align-items: center;
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: var(--max-width);
  padding: 0 var(--marginX);
  position: sticky;
  top: 0;
  z-index: 1;
}

.nav-logo {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 8px;
  padding-left: 0;
  z-index: 11;
  
  svg {
    height: auto;
    max-width: 112px;
    width: 100%;
  }
  
  @media (min-width: 1024px) {
    padding: 20px;
    padding-left: 0;
    
    svg {
      max-width: 200px;
      min-width: 180px;
    }
  }
}

.footer-element {
  background-color: var(--beige);
  color: var(--purple);
  display: grid;
  grid-gap:0;
  padding-bottom: var(--pad-md);
  
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: var(--pad-x);
    padding: var(--pad-md) var(--pad-x);
  }
}
